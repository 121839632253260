import Section from "@mittwald/flow-react-components/Section";
import LayoutCard from "@mittwald/flow-react-components/LayoutCard";
import Heading from "@mittwald/flow-react-components/Heading";
import { useSearchParams } from "react-router";
import { Suspense, useState } from "react";
import { AppInstallationSelection } from "../components/AppInstallationSelection.tsx";
import { AppInstallationPHPSettings } from "../components/AppInstallationPHPSettings.tsx";
import { AppInstallationPHPSettingsSkeleton } from "../components/AppInstallationPHPSettingsSkeleton.tsx";
import { AppInstallationSelectionSkeleton } from "../components/AppInstallationSelectionSkeleton.tsx";
import { OPcacheStats } from "../components/OPcacheStats.tsx";
import { OPcacheStatsSkeleton } from "../components/OPcacheStatsSkeleton.tsx";
import { MissingParamError } from "@hueskehilker/extension-lib-frontend";
import { APCuStats } from "../components/APCuStats.tsx";

export const Editor = () => {
  const [searchParams] = useSearchParams();
  const [appInstallationId, setAppInstallationId] = useState<string | null>(
    null,
  );

  const projectId = searchParams.get("projectId");
  if (!projectId) {
    throw new MissingParamError("ProjectId nicht gegeben");
  }

  const extensionInstanceId = searchParams.get("extensionInstanceId");
  if (!extensionInstanceId) {
    throw new MissingParamError("ExtensionInstanceId nicht gegeben");
  }

  return (
    <>
      <LayoutCard>
        <Section>
          <Suspense
            fallback={
              <>
                <AppInstallationSelectionSkeleton />
              </>
            }
          >
            <AppInstallationSelection
              projectId={projectId}
              extensionInstanceId={extensionInstanceId}
              appInstallationSelected={setAppInstallationId}
            />
          </Suspense>
        </Section>
      </LayoutCard>

      {appInstallationId && (
        <>
          <LayoutCard>
            <Suspense
              fallback={
                <Section>
                  <AppInstallationPHPSettingsSkeleton />
                </Section>
              }
            >
              <AppInstallationPHPSettings
                key={appInstallationId}
                projectId={projectId}
                appInstallationId={appInstallationId}
                extensionInstanceId={extensionInstanceId}
              />
            </Suspense>
          </LayoutCard>
          <LayoutCard>
            <Section>
              <Heading>Cache-Auslastung deiner App</Heading>
              <Suspense fallback={<OPcacheStatsSkeleton />}>
                <OPcacheStats
                  key={appInstallationId}
                  projectId={projectId}
                  appInstallationId={appInstallationId}
                  extensionInstanceId={extensionInstanceId}
                />
              </Suspense>
              <Suspense fallback={<OPcacheStatsSkeleton />}>
                <APCuStats
                  key={appInstallationId}
                  projectId={projectId}
                  appInstallationId={appInstallationId}
                  extensionInstanceId={extensionInstanceId}
                />
              </Suspense>
            </Section>
          </LayoutCard>
        </>
      )}
    </>
  );
};
