import { Outlet, ScrollRestoration } from "react-router";
import { Suspense } from "react";
import { openobserveRum } from "@openobserve/browser-rum";
import { openobserveLogs } from "@openobserve/browser-logs";
import {
  CustomLoadingSpinner,
  Footer,
  useAtrekAuthentication,
} from "@hueskehilker/extension-lib-frontend";
import LayoutCard from "@mittwald/flow-react-components/LayoutCard";
import Section from "@mittwald/flow-react-components/Section";
import Heading from "@mittwald/flow-react-components/Heading";
import { authenticationATReK } from "./openapi/api-client";

if (window.location.hostname != "localhost") {
  const options = {
    clientToken: import.meta.env.VITE_OPENOBSERVE_CLIENT_TOKEN,
    applicationId: import.meta.env.VITE_OPENOBSERVE_APPLICATION_ID,
    site: import.meta.env.VITE_OPENOBSERVE_URL,
    service: import.meta.env.VITE_OPENOBSERVE_APPLICATION_NAME,
    env: import.meta.env.MODE,
    version: import.meta.env.VITE_OPENOBSERVE_APPLICATION_VERSION,
    organizationIdentifier: import.meta.env.VITE_OPENOBSERVE_ORG,
    insecureHTTP: false,
    apiVersion: "v1",
  };

  openobserveRum.init({
    applicationId: options.applicationId,
    clientToken: options.clientToken,
    site: options.site,
    organizationIdentifier: options.organizationIdentifier,
    service: options.service,
    env: options.env,
    version: options.version,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    apiVersion: options.apiVersion,
    insecureHTTP: options.insecureHTTP,
    defaultPrivacyLevel: "allow",
  });

  openobserveRum.startSessionReplayRecording();

  openobserveLogs.init({
    clientToken: options.clientToken,
    site: options.site,
    organizationIdentifier: options.organizationIdentifier,
    service: options.service,
    env: options.env,
    version: options.version,
    forwardErrorsToLogs: true,
    insecureHTTP: options.insecureHTTP,
    apiVersion: options.apiVersion,
  });

  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("userId");
  const projectId = urlParams.get("projectId");
  const appInstallationId = urlParams.get("appInstallationId");
  const extensionInstanceId = urlParams.get("extensionInstanceId");

  openobserveRum.setUser({
    id: userId != null ? userId : undefined,
  });

  openobserveRum.setGlobalContext({
    projectId,
    appInstallationId,
    extensionInstanceId,
  });
}

export const App = () => {
  const authenticated = useAtrekAuthentication(authenticationATReK);

  return (
    <>
      <ScrollRestoration />
      <Suspense fallback={<>Loading...</>}>
        <LayoutCard>
          <Section>
            <Heading level={1}>PHP-Einstellungen</Heading>
            {!authenticated && (
              <CustomLoadingSpinner text={"Authentifiziere"} />
            )}
          </Section>
        </LayoutCard>
        {authenticated && <Outlet />}
        <LayoutCard>
          <Footer />
        </LayoutCard>
      </Suspense>
    </>
  );
};

export default App;
